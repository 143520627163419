import { defineStore } from "pinia"

import type { Stream } from "@/types/types"

export const useStreamStore = defineStore({
  id: "stream-store",
  // https://pinia.vuejs.org/core-concepts/state.html#typescript
  state: () => {
    return {
      streams: [] as Stream[],
    }
  },
  actions: {
    addStream(stream: Stream) {
      this.streams.push(stream)
    },
    updateStream(id: string, stream: Stream) {
      const index = this.streams.findIndex((s) => s.id === id)
      if (index !== -1) this.streams[index] = stream
    },
    updateStreams(streams: Stream[]) {
      this.streams = streams
    },
  },
  getters: {},
})
