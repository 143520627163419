import { useDestinationStore } from "@/stores/destination"
import { useSourceStore } from "@/stores/source"
import { useStreamStore } from "@/stores/stream"

import type {
  DestinationResults,
  SourceResults,
  StreamResults,
} from "@/types/requests"

const toastError = (message: string) => {
  const { $toast } = useNuxtApp()
  $toast.error(message)
}

// fetch destinations and update store
export const useRefreshDestinations = () => {
  return useAPIFetch<DestinationResults>("/destinations/").then((response) => {
    if (response.error.value) {
      toastError("Unable to load destinations")
    } else if (response.data.value?.destinations) {
      useDestinationStore().updateDestinations(response.data.value.destinations)
    }
    return response
  })
}

// fetch sources and update store
export const useRefreshSources = () => {
  return useAPIFetch<SourceResults>("/sources/").then((response) => {
    if (response.error.value) {
      toastError("Unable to load sources")
    } else if (response.data.value?.sources) {
      useSourceStore().updateSources(response.data.value.sources)
    }
    return response
  })
}

// fetch streams and update store
export const useRefreshStreams = () => {
  return useAPIFetch<StreamResults>("/streams/").then((response) => {
    if (response.error.value) {
      toastError("Unable to load streams")
    } else if (response.data.value?.streams) {
      useStreamStore().updateStreams(response.data.value.streams)
    }
    return response
  })
}
