import { defineStore } from "pinia"

import type { Source, SourceType } from "@/types/types"

export const useSourceStore = defineStore({
  id: "source-store",
  // https://pinia.vuejs.org/core-concepts/state.html#typescript
  state: () => {
    return {
      sources: [] as Source[],
    }
  },
  actions: {
    addSource(source: Source) {
      this.sources.push(source)
    },
    updateSource(id: string, source: Source) {
      const index = this.sources.findIndex((s) => s.id === id)
      if (index !== -1) this.sources[index] = source
    },
    updateSources(sources: Source[]) {
      this.sources = sources
    },
  },
  getters: {
    currentSourceTypes(): SourceType[] {
      const sTypes: SourceType[] = this.sources.map((s) => s.type)
      return [...new Set(sTypes)]
    },
    hasSources(): boolean {
      return this.sources.length > 0
    },
  },
})
